import type { TopologyResponse } from '@layers/types/types/networkTopology';

const storeOptions = {
    persist: {
        paths: ['topology'],
        storage: persistedState.localStorage,
    },
};

export default (project: TProject) => {
    const projectId = project.id;
    const { applyPolicies } = useDefaultPolicies();

    return defineStore(
        `project-${projectId}-topology`,
        () => {
            const pending = ref(false);
            const topology: Ref<TopologyResponse> = ref({
                dds: {},
                links: [],
                peers: [],
                routers: {},
            });
            async function refresh() {
                try {
                    pending.value = true;
                    const t = await $fetch<TopologyResponse>(
                        `/api/projects/${projectId}/topology`,
                        {
                            timeout: 15000,
                            cache: 'no-cache',
                            onResponseError: async (ctx) => {
                                if (ctx.response.status === 401) {
                                    await navigateTo('/login');
                                }
                            },
                        }
                    );
                    topology.value = {
                        ...t,
                        routers: Object.keys(t.routers)
                            .sort()
                            .reduce(
                                (acc, k) => ({ ...acc, [k]: t.routers[k] }),
                                {}
                            ),
                        dds: applyPolicies(t.dds),
                    };
                } catch {
                    topology.value = {
                        dds: {},
                        links: [],
                        peers: [],
                        routers: {},
                    };
                } finally {
                    pending.value = false;
                }
            }

            return {
                topology,
                refresh,
                isLoading: useDebounce(pending, 1500),
            };
        },
        storeOptions
    )();
};
