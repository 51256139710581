import type { DDSTopologyData } from '@layers/types/types/dds';

export default () => {
    const appConfig = useAppConfig();
    const ddsPolicies = appConfig.dds.policies;

    const knownPolicies = Object.keys(
        ddsPolicies
    ) as (keyof typeof ddsPolicies)[];

    const writerPolicies = knownPolicies.filter((policyName) =>
        ddsPolicies[policyName].target.includes('dataWriter')
    );
    const readerPolicies = knownPolicies.filter((policyName) =>
        ddsPolicies[policyName].target.includes('dataReader')
    );

    function applyReaderPolicies(reader: DDSRW) {
        return {
            ...reader,
            qos: readerPolicies.reduce((policies, policyName) => {
                return {
                    ...policies,
                    [policyName]: {
                        ...(ddsPolicies[policyName].defaultConfigurations
                            ?.reader ||
                            ddsPolicies[policyName].defaultConfiguration),
                        ...(reader.qos[policyName] || {}),
                    },
                };
            }, {}),
        };
    }

    function applyWriterPolicies(writer: DDSRW) {
        return {
            ...writer,
            qos: writerPolicies.reduce((policies, policyName) => {
                return {
                    ...policies,
                    [ddsPolicies[policyName].policyName || policyName]: {
                        ...(ddsPolicies[policyName].defaultConfigurations
                            ?.writer ||
                            ddsPolicies[policyName].defaultConfiguration),
                        ...(writer.qos[policyName] || {}),
                    },
                };
            }, {}),
        };
    }

    function applyPolicies(dds: DDSTopologyData): DDSTopologyData {
        const routerIds = Object.keys(dds);
        const topology: DDSTopologyData = {};

        routerIds.forEach((routerId) => {
            const ddsRouter: DDSRouter = dds[routerId];
            const participantIds = Object.keys(ddsRouter.participants);

            topology[routerId] = {
                domain: dds[routerId].domain,
                id: routerId,
                participants: participantIds.reduce(
                    (ddsNodeParticipants, participantId) => {
                        const participant =
                            ddsRouter.participants[participantId];

                        return {
                            ...ddsNodeParticipants,
                            [participantId]: {
                                raw: participant.raw,
                                readers:
                                    participant.readers.map(
                                        applyReaderPolicies
                                    ),
                                writers:
                                    participant.writers.map(
                                        applyWriterPolicies
                                    ),
                            },
                        };
                    },
                    {} as DDSNodeParticipants
                ),
            };
        });

        return topology;
    }

    return { applyReaderPolicies, applyWriterPolicies, applyPolicies };
};
